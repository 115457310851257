// /*
//     DEMO STYLE
// */

// body {
//     background: #fafafa;
// }

// p {
//     font-size: 1.1em;
//     font-weight: 300;
//     line-height: 1.7em;
//     color: #999;
// }

// a,
// a:hover,
// a:focus {
//     color: inherit;
//     text-decoration: none;
//     transition: all 0.3s;
// }

// .navbar {
//     padding: 15px 10px;
//     background: #fff;
//     border: none;
//     border-radius: 0;
//     margin-bottom: 40px;
//     box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
// }

// .navbar-btn {
//     box-shadow: none;
//     outline: none !important;
//     border: none;
// }

// .line {
//     width: 100%;
//     height: 1px;
//     border-bottom: 1px dashed #ddd;
//     margin: 40px 0;
// }

// i,
// span {
//     display: inline-block;
// }

// .btn.dropdown-toggle::after {
//     content: none;
// }

// /* ---------------------------------------------------
//     SIDEBAR STYLE
// ----------------------------------------------------- */

// .wrapper {
//     display: flex;
//     align-items: stretch;
// }

// #sidebar {
//     // min-width: 250px;
//     // max-width: 250px;
//     // /*background: #7386D5;*/
//     // // background: #080808;
//     // // color: #fff;
//     // transition: all 0.3s;
// }

//     #sidebar.active {
//         min-width: 80px;
//         max-width: 80px;
//         text-align: center;
//     }

//         #sidebar.active .sidebar-header h3,
//         #sidebar.active .CTAs {
//             display: none;
//         }

//         #sidebar.active .sidebar-header strong {
//             display: block;
//         }

//     #sidebar ul li a {
//         text-align: left;
//     }

//     #sidebar.active ul li a {
//         padding: 20px 10px;
//         text-align: center;
//         font-size: 0.85em;
//     }

//         #sidebar.active ul li a i {
//             margin-right: 0;
//             display: block;
//             font-size: 1.8em;
//             margin-bottom: 5px;
//         }

//     #sidebar.active ul ul a {
//         padding: 10px !important;
//     }

//     #sidebar.active .dropdown-toggle::after {
//         top: auto;
//         bottom: 10px;
//         right: 50%;
//         -webkit-transform: translateX(50%);
//         -ms-transform: translateX(50%);
//         transform: translateX(50%);
//     }

//     #sidebar .sidebar-header {
//         padding: 10px 20px 10px 10px;
//         /*background: #6d7fcc;*/
//         background: #111;
//     }

// #sidebar .sidebar-lang {
//     padding: 10px 10px 0px 10px;
//     width: 10px;
// }
//         #sidebar .sidebar-header strong {
//             display: none;
//             font-size: 1.8em;
//         }

//     #sidebar ul.components {
//         padding: 20px 0;
//         border-bottom: 1px solid #222;
//     }

//     #sidebar ul li a {
//         padding: 10px;
//         font-size: 1.1em;
//         display: block;
//     }

//         #sidebar ul li a:hover {
//             color: #fff;
//             background: #333;
//         }

//         #sidebar ul li a i {
//             margin-right: 10px;
//         }

//     #sidebar ul li.active > a,
//     a[aria-expanded="true"] {
//         color: #fff;
//         background: #111;
//     }

// #sidebar .bottom_button {
//     position: absolute;
//     display: inline-block;
//     bottom: 0;
//     left: 15px;
// }

// a[data-toggle="collapse"] {
//     position: relative;
// }

// .dropdown-toggle::after {
//     display: block;
//     position: absolute;
//     top: 50%;
//     right: 20px;
//     transform: translateY(-50%);
// }

// ul ul a {
//     font-size: 0.9em !important;
//     padding-left: 30px !important;
//     /*background: #6d7fcc;*/
//     background: #111;
// }

// ul.CTAs {
//     padding: 20px;
// }

//     ul.CTAs a {
//         text-align: center;
//         font-size: 0.9em !important;
//         display: block;
//         border-radius: 5px;
//         margin-bottom: 5px;
//     }

// a.download {
//     background: #fff;
//     color: #111;
// }

// a.article,
// a.article:hover {
//     background: #111 !important;
//     color: #fff !important;
// }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/*@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }

    #sidebar .sidebar-header strong {
        display: block;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

        #sidebar ul li a span {
            font-size: 0.85em;
        }

        #sidebar ul li a i {
            margin-right: 0;
            display: block;
        }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
    
    #sidebar .sidebar-lang {
        padding: 10px;
    }
}*/


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
a {
    color: #fff;
    text-decoration: none;
}
$accent: #1874EF;
.sidebar {
    background-color: #303841;
    color: #fff;
    width: 100%;
    transition: max-width .2s;
    &_large {
        max-width: 300px;
        .sidebar__logo-desctop {
            display: block;
        }
        .sidebar__logo-mobile {
            display: none;
        }
    }
    &_small {
        max-width: 80px;
        .sidebar__logo-desctop {
            display: none;
        }
        .sidebar__logo-mobile {
            display: block;
        }
        .sidebar__header {
            padding: 10px;
            margin-bottom: 10px;
        }
        .profile {
            padding: 10px;
            justify-content: center;
            &__wrap {
                margin-right: 0;
                margin-bottom: 10px;
                width: 60px;
                height: 60px;
                font-size: 26px;
            }
            &__login {
                display: none;
            }
            &__logout {
                span {
                    margin: 0;
                    font-size: 35px;
                }
                .text {
                    display: none;
                }
            }
        }
        .menu-nav {
            &__item {
                .text {
                    display: none;
                }
                a {
                    justify-content: center;
                }
                .material-icons {
                    font-size: 35px;
                    margin-right: 0;
                }
            }
        }
        .call-phone {
            left: 10px;
            bottom: 10px;
        }
        .profile-nav {
            &__item {
                a {
                    justify-content: center;
                    .material-icons {
                        width: 35px;
                        height: 35px;
                        font-size: 24px;
                        margin-right: 0;
                    }
                    .text {
                        display: none;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
            ul { 
                li {
                    a {
                        padding-left: 15px;
                        .material-icons {
                            font-size: 35px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .sidebar-lang #lang_nav_menu {
            padding: 5px;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        border-bottom: 2px solid #7070708a;
        margin-bottom: 15px;
    }
    &__logo {
        font-size: 24px;
        font-weight: 700;
    }
    &__logo-mobile {
        display: none;
    }
    &__burger {
        display: flex;
        align-items: center;
        .material-icons {
            font-size: 30px;
        }
    }
}
.menu-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        &:first-child a::before {
            content: '';
            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;
            max-width: 100%;
            height: 1px;
            background-color: #7070708a;
            display: block;
        }
        a {
            display: flex;
            align-items: center;
            padding: 18px 15px;
            border-left: 3px solid transparent;
            transition: all .3s ease;
            position: relative;
            box-sizing: border-box;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            &:hover {
                text-decoration: none;
                color: #fff;
                border-left: 3px solid $accent;
                background-color: #442A9D;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                max-width: 100%;
                height: 1px;
                background-color: #7070708a;
                display: block;
            }
            .material-icons {
                margin-right: 20px;
            }
        }
    }
}
.profile-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        a {
            display: flex;
            padding: 15px;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            position: relative;
            border-left: 3px solid transparent;
            .material-icons {
                width: 26px;
                height: 26px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 20px;
                color: #303841;
                font-size: 18px;
            }
            &::after {
                font-size: 18px;
                right: 20px;
                position: absolute;
            }
            &:hover {
                text-decoration: none;
                color: #fff;
                border-color: $accent;
                background-color: #442A9D;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                max-width: 100%;
                height: 1px;
                background-color: #7070708a;
                display: block;
            }
        }
    }
    ul {
        li {
            a {
                padding-left: 60px;
                font-weight: 400;
                .material-icons {
                    width: auto;
                    height: auto;
                    background: transparent;
                    color: #fff;
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    &__wrap {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $accent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 30px;
        margin-right: 15px;
    }
    &__login {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 5px;
        span {
            margin-right: 7px;
            font-size: 20px;
        }
    }
    &__logout {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        transition: none;
        span {
            margin-right: 10px;
            font-size: 17px;
        }
        .text {
            font-size: 14px;
        }
    }
}
.sidebar-lang {
    margin: 15px;
    #lang_nav_menu {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        svg {
            margin-right: 5px;
        }
        &::after {
            display: none;
        }
        &:focus {
            box-shadow: none;
            outline: none;
            border-color: $accent;
        }
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        span {
            margin-right: 5px;
            margin-top: -5px;
        }
    }
}