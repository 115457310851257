
$accent: #1874EF;
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
}

.loader {
    border-top: 16px solid blue;
    border-right: 16px solid green;
    border-bottom: 16px solid red;
    border-left: 16px solid pink;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    display: block;
    position: absolute;               /* 2 */
    top: 50%;      
    left: 50%;      
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.call-phone {
    position: absolute;
    display: block;
    margin: 0;
    width: 60px;
    height: 60px;
    font-size: 30px;
    background-color: $accent;
    border-radius: 50%;
    box-shadow:
      0 0 0 0em rgba($accent, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
    cursor: pointer;
    bottom: 15px;
    left: 15px;
    z-index: 999;
    color: #fff;
    &.call-close::after {
        content: 'close';
    }
    &__number {
        span {
            opacity: .7;
        }
    }
}

.call-phone::before,
.call-phone::after {
    position: absolute;
    content: "";
}

.call-phone::before {
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background-color: rgba(#fff, 0.1);
    border-radius: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
}

.call-phone::after {
    font-family: "Material Icons";
    content: "phone";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 1;
}

.call-phone.is-animating {
    animation: phone-outer 3000ms infinite;

    &::before {
        animation: phone-inner 3000ms infinite;
    }

    &::after {
        animation: phone-icon 3000ms infinite;
    }
}

@keyframes phone-outer {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0.1),
        0em 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0.5em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    }

    @keyframes phone-inner {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0);
    }
    33.3333% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0.9);
    }
    66.6666% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
}

@keyframes phone-icon {
    0% { transform: translate3d(0em, 0, 0); }
    2% { transform: translate3d(0.01em, 0, 0); }
    4% { transform: translate3d(-0.01em, 0, 0); }
    6% { transform: translate3d(0.01em, 0, 0); }
    8% { transform: translate3d(-0.01em, 0, 0); }
    10% { transform: translate3d(0.01em, 0, 0); }
    12% { transform: translate3d(-0.01em, 0, 0); }
    14% { transform: translate3d(0.01em, 0, 0); }
    16% { transform: translate3d(-0.01em, 0, 0); }
    18% { transform: translate3d(0.01em, 0, 0); }
    20% { transform: translate3d(-0.01em, 0, 0); }
    22% { transform: translate3d(0.01em, 0, 0); }
    24% { transform: translate3d(-0.01em, 0, 0); }
    26% { transform: translate3d(0.01em, 0, 0); }
    28% { transform: translate3d(-0.01em, 0, 0); }
    30% { transform: translate3d(0.01em, 0, 0); }
    32% { transform: translate3d(-0.01em, 0, 0); }
    34% { transform: translate3d(0.01em, 0, 0); }
    36% { transform: translate3d(-0.01em, 0, 0); }
    38% { transform: translate3d(0.01em, 0, 0); }
    40% { transform: translate3d(-0.01em, 0, 0); }
    42% { transform: translate3d(0.01em, 0, 0); }
    44% { transform: translate3d(-0.01em, 0, 0); }
    46% { transform: translate3d(0em, 0, 0); }
}

.call-popup {
    color: #333;
    transition: all .3s ease;
    &__content {
        position: fixed;
        left: 75px;
        bottom: 75px;
        background: #fff;
        box-shadow: 0 3px 14px rgba(17,34,51,0.16);
        padding: 15px;
        z-index: 999;
        padding: 20px;
        max-width: 320px;
    }
    &__backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 99;
    }
    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    &.hide {
        visibility: hidden;
        opacity: 0;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    .input-group {
        padding: 10px 0 15px;
        input, button {
            border-radius: 0;
        }
        input:focus {
            box-shadow: none;
            outline: none;
            border-color: #28a745;
        }
    }
}

.form-group {
    .input-group-prepend {
        z-index: 2;
    }
    .input-group {
        select {
            margin-left: -3px;
        }
    }
}
.date-group {
    input {
        line-height: 32px;
        max-width: 332px;
        width: 100%;
        padding-left: 10px;
        &:last-child {
            margin-left: -1px;
        }
    }
    .react-datepicker__input-container {
        min-width: 332px;
        width: 100%;
    }
    .react-datepicker-wrapper {
        max-width: 330px;
        width: 100%;
    }
}
.customer-search {
    .btn {
        width: 100%;
    }
}
.billboard-popup-list {
    margin-top: 15px;
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.billboard-edit {
    &.hide {
        display: none;
    }
    &.show {
        display: block;
    }
}
.btn-edit-group {
    .btn-cencel {
        margin-right: 10px;
    }
}
.call-phone-scroll {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}